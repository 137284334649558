import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import './preference.css';
import { saveQnaQuestion, getQuestions, getChatResponse, saveUserName } from '../services/apiService';
import { addPreferenceQuestion } from '../../slices/preferenceSlice';
import { loginData, login } from '../../slices/loginSlice';
import { toast } from 'react-toastify';
import { LoaderContext } from '../services/LoaderContext';
import ProgressBarComponent from '../services/ProgressBarComponent';
import ChatLoader from "../services/chat-loader";
import { scrollToBottom, shouldDispatchQuestion, checkIsValid } from '../utils/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';

// Agent chat
import '../agent-mira/chat.css'; 
import DOMPurify from 'dompurify';
import ChatInput from "../property/chat-input";
import StickyCard from "../property/sticky-card";

const minValue = 100000;
const maxValue = 10000000;
const step = 100000;
const pageName = 'user_preferences';

const PreferenceHomeContent = ({propertyPreferenceQuestions, setPropertyPreferenceQuestions, }) => {

    const bottomRef = useRef(null);
    const userNickname = useRef('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hasFetched = useRef(false);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const propertyId = useSelector((state) => state.propertySlice.propertyId);
    const userDetails = useSelector(loginData);
    const [userInfo, setUserInfo] = useState(userDetails);
    const [username, setUsername] = useState(userDetails?.nick_name);
    const [checkedQuestion, setCheckedQuestion] = useState({});
    const [budget, setBudget] = useState(minValue);


    // Agent mira vars
    const [userInput, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    // End


    useEffect(() => {
        if(userDetails){
            setUserInfo(userDetails);
            if(userDetails?.is_recurring){
                setUsername(userDetails?.nick_name);
            }
        }
    }, [hasFetched, setUsername, setUserInfo, userDetails]);

    const handleProgressBarValue = (value) => {
        setBudget(value);
    };

    const handleInputKey = (e) => {
        handleKeyDown(e);
        if (e.key === 'Enter') {
            handleNameChange(e);
        }
    };

    const handleNameChange = (event) => {
        if (event.target.value !== '') {
            setUsername(event.target.value);
        }
    };

    const saveUsername = async () => {
        if (username !== '') {
            try {
                showLoader();
                const response = await saveUserName({"nickname": username});
                if(response.code === 200){
                    const updatedUserInformation = {
                        ...userDetails,
                        is_recurring: true,
                        isSaved: true,
                        nick_name: username
                    };
                    setUserInfo(updatedUserInformation);
                    dispatch(login(updatedUserInformation));
                }
                hideLoader()
            }
            catch (error) {
                toast.error("Unable to save the user name, try again later.");
            }
        }
        else {
            toast.error("Name is required.")
        }
    };


    // ===============================================================================================================
    // ===============================================================================================================
    // ===============================================================================================================
    
    const selectOption = (question, optionId, theOption) => {
        const updatedQuestions = propertyPreferenceQuestions?.map(q => {
            if (q.question_id === question.question_id) {
                let updatedResponseOptions = [];
    
                // Handling the exclusive option
                if (theOption?.exclusive && !theOption.active) {
                    // The current option clicked is exclusive
                    // This means that the exclusive option is checked and all others are supposed to be made unchecked
                    updatedResponseOptions = q.response_options.map(option => {
                        if (option.option_id !== optionId) {
                            return {
                                ...option,
                                active: false,
                                isChecked: false,
                            };
                        } else {
                            return {
                                ...option,
                                active: true,
                                isChecked: true,
                            };
                        }
                    });
                } else {
                    // The current option clicked is NOT the exclusive one
                    updatedResponseOptions = q.response_options.map(option => {
                        if (option.option_id === optionId) {
                            return {
                                ...option,
                                active: !option.active,
                                isChecked: !option.active,
                            };
                        }
                        if (option.exclusive === 1) {
                            return {
                                ...option,
                                active: false,
                                isChecked: false,
                            };
                        }
                        return option;
                    });
                }
    
                return {
                    ...q,
                    response_options: updatedResponseOptions,
                };
            }
            return q;
        });
    
        setPropertyPreferenceQuestions(updatedQuestions);
        setCheckedQuestion(question);
        if(question.hasOwnProperty("response")){
            saveUpdatedResponse(question,updatedQuestions);
        }
    };

    const saveUpdatedResponse = async(question,updatedQuestions) =>{
        const checkedOptions = getCheckedResponses(updatedQuestions, question);
        await saveQnaQuestion({ question_id: question.question_id, response: checkedOptions }, pageName, propertyId);
    }
    
    const saveQuestion = async () => {
        const findQuestion = propertyPreferenceQuestions?.find(item => item.question_type === "numeric-input-with-slider");
        if(findQuestion && findQuestion !== undefined && findQuestion !== null && Object.keys(findQuestion).length > 0){
            showLoader();
            let payload = [];
            payload.push(budget.toLocaleString());
            const response = await saveQnaQuestion({ question_id: findQuestion.question_id, response: payload }, pageName, propertyId);
            if (response.code === 200) { 
                const updatedWithSliderValue = propertyPreferenceQuestions?.map(ques =>
                    ques.question_id === findQuestion.question_id
                        ? {
                            ...ques,
                            response: payload
                        }
                        : ques
                );
                setPropertyPreferenceQuestions(updatedWithSliderValue);
                dispatch(addPreferenceQuestion(updatedWithSliderValue));
                navigate(`/${propertyId}/market`);
            }
            else {
                toast.error("Unable to save the question.")
            }
            hideLoader();
        }
        else {
            const question = checkedQuestion;
            const checkedOptions = getCheckedResponses(propertyPreferenceQuestions, question);
            const updatedWithCheckedOptions = propertyPreferenceQuestions?.map(ques =>
                ques.question_id === question.question_id
                    ? {
                        ...ques,
                        response: checkedOptions
                    }
                    : ques
            );
    
            if(question?.isLast === false && !question.hasOwnProperty("response")){
                setLoading(true);
            }
        
            const response = await saveQnaQuestion({ question_id: question.question_id, response: checkedOptions }, pageName, propertyId);
            if (response.code === 200) {
                const questionResponse = await getQuestions(propertyId, 'user_preferences');
                if(questionResponse.code === 200){
                    setLoading(false);
                    if (shouldDispatchQuestion(questionResponse.response, propertyPreferenceQuestions)) {
                        setPropertyPreferenceQuestions([...updatedWithCheckedOptions, questionResponse.response]);
                    }
                    scrollToBottom(bottomRef);
                }
                else {
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
            }
        }
    }

    const getCheckedResponses = (allQuestions, question) => {
        const selectedQuestion = allQuestions.find(ques => ques.question_id === question.question_id);
        const checkedOptions = selectedQuestion.response_options.filter(option => option.isChecked === true);
        const payload = []
        checkedOptions.forEach(option => {
            payload.push(option.option_id)
        })
        return payload;
    }

    // ===============================================================================================================
    // ===============================================================================================================
    // ================================Agent Mira Chat Functions =====================================================
    // ===============================================================================================================
    // ===============================================================================================================

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && userInput.trim() !== '') {
            saveChatAndGetResponse('user', userInput);
        }
    };

    const getChat = () => {
        if (userInput.trim() !== '') {
            saveChatAndGetResponse('user', userInput);
        }
    };

    const getChatSuggestions = (suggestion) => {
        saveChatAndGetResponse('user', suggestion);
    };

    const handleChatResponse = (responseMessage) => {
        const updateChat = { sender: "mira", message: responseMessage, chat: true };
        setPropertyPreferenceQuestions(prevState => [...prevState, updateChat]);
    };
    
    const saveChatAndGetResponse = async (type, message) => {
        const errorMessage = "There is some issue with accessing our AI engine, can you please ask your question again.";
        setInput('');
        const newMessage = { sender: type, message: message, chat: true };
        setPropertyPreferenceQuestions(prevState => [...prevState, newMessage]);
    
        try {
            setLoading(true);
            const chat_response = await getChatResponse({ "user_str": message, "page": "user_preferences" }, propertyId);
    
            if (chat_response.code === 200) {
                if (chat_response.response.mira_chat_response) {
                    handleChatResponse(chat_response.response.mira_chat_response);
                } 
                else {
                    handleChatResponse(errorMessage);
                }
            } 
            else {
                handleChatResponse(errorMessage);
            }
        } 
        catch (error) {
            const updateChat = { sender: "mira", message: errorMessage, chat: true };
            setPropertyPreferenceQuestions(prevState => [...prevState, updateChat]);
        } 
        finally {
            setLoading(false);
        }

        if (checkIsValid(propertyPreferenceQuestions)) {
            setPropertyPreferenceQuestions(prevState => {
                const manorQues = [...prevState];
                const questionsWithBothKeys = manorQues.filter(
                    question => question.hasOwnProperty('question_id') && !question.hasOwnProperty('response')
                );
        
                if (questionsWithBothKeys.length > 0) {
                    const index = manorQues.findIndex(
                        item => item.question_id === questionsWithBothKeys[0].question_id
                    );
        
                    if (index !== -1) {
                        const [existingQuestion] = manorQues.splice(index, 1);
                        manorQues.push(existingQuestion);
                    }
                }
        
                return manorQues;
            });
            setLoading(false);
        }
    };


    return (
        <>
            {(userInfo?.is_recurring || userInfo?.isSaved) ?
                <>
                    <div className="container_main">
                        <div className="part right-section">
                            <div className="chat-container">
                                <div className="chat-header fixed-button-top">
                                    <div className="message-holder mr-85">
                                        <div className="question-row heres-your-offer">
                                            <h3 className="heres-your-offer">
                                                {userInfo?.is_recurring && !userInfo?.isSaved &&
                                                    <span>Nice to see you again,</span> 
                                                }
                                                {userInfo?.isSaved &&
                                                    <span>Nice to meet you,</span> 
                                                }
                                                <span className="jenna ms-2">{username}.</span>
                                            </h3>
                                            {userInfo?.is_recurring && !userInfo?.isSaved &&
                                                <h1 className="lets-take-a font-size-xl">What Are You Looking For?</h1>
                                            }
                                            {userInfo?.isSaved &&
                                                <h1 className="lets-take-a font-size-xl">Let's take a minute to get to know you better!</h1>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="chat-window pt-0">
                                    <div className="content">
                                        {propertyPreferenceQuestions && propertyPreferenceQuestions?.length > 0 &&
                                            <>
                                                {propertyPreferenceQuestions?.map((ques, index) => (
                                                    <>
                                                        {ques?.chat === true ? 
                                                            <>
                                                                {/* Agent mira chat list */}
                                                                {ques?.sender === 'mira' &&
                                                                    <div className="message-holder mira-chat ml-85 mr-85" data-test-id={`agent-mira${index}`} key={index}>
                                                                        <div className="message-bubble">
                                                                            <div className={`label ${index === 0  ? 'd-flex font-weight-bold' : 'd-none'}`}>
                                                                                Agent Mira
                                                                            </div>
                                                                            <div className="message-text">
                                                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ques.message) }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {ques?.sender === 'user' &&
                                                                    <div className="message-holder user-block ml-85" data-test-id={`user-response${index}`} data-content={`${atob(userDetails?.firstName).charAt(0).toUpperCase()}${atob(userDetails?.lastName).charAt(0).toUpperCase()}`} key={index}>
                                                                        <div className="message-bubble reply-bubble">
                                                                            
                                                                            <div className="message-text d-flex js-start">
                                                                                <div className="mb-2">
                                                                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ques.message) }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {/* Agent Mira chat ends */}
                                                            </> 
                                                        :  
                                                            <>
                                                                <div className="message-holder mira-chat ml-85 mr-85" data-test-id={`question-${index}`} key={index}>
                                                                    <div className="message-bubble">
                                                                        <div className={`label ${index === 0  ? 'd-flex font-weight-bold' : 'd-none'}`}>
                                                                            Agent Mira
                                                                        </div>
                                                                        <div className="message-text">
                                                                            {ques?.question_text}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="message-holder sub-card ml-85 mr-85">
                                                                    <div className="message-bubble bg-none p-0 mt-2" data-test-id={`option-response-${index}`}>
                                                                        {ques.question_type === "multiple-select" && (
                                                                            <div className="chat_row">
                                                                                <div className="chat_mira_child">
                                                                                    {ques?.response_options?.length > 0 && (
                                                                                        <div className={` ${loading ? 'disable' : ''}`}>
                                                                                            <div className="commute-checkbox-parent">
                                                                                                {ques.response_options.map((option) => (
                                                                                                    <>
                                                                                                        {(option?.isChecked === true) ?
                                                                                                            <div className="commute-checkbox" data-test-id={`option-checked${option.option_id}`} onClick={() => selectOption(ques, option.option_id, option)}>
                                                                                                                <div className="check">
                                                                                                                    <img className="icon" loading="lazy" alt="" src={`${process.env.PUBLIC_URL}/images/preference/screen1/icon.svg`} />
                                                                                                                </div>
                                                                                                                <div className="commute-friendly">{option?.option_text}</div>
                                                                                                            </div>
                                                                                                        :
                                                                                                            <div className="checkbox-container" data-test-id={`option-unchecked${option.option_id}`} onClick={() => selectOption(ques, option.option_id, option)}>
                                                                                                                <div className="checkbox">
                                                                                                                    <div className="checkbox-base"></div>
                                                                                                                </div>
                                                                                                                <div className="kid-friendly">{option?.option_text}</div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                        {(propertyPreferenceQuestions?.length > 1) && ques.question_type === "numeric-input-with-slider" && (
                                                                            <>
                                                                                <div className="message-holder sub-card ml-0">
                                                                                    <div className="message-bubble bg-none p-0 gap-10">
                                                                                        <ProgressBarComponent minValue={minValue} maxValue={maxValue} step={step} startingValue={100000} prefix={"$"} ques={ques} onData={handleProgressBarValue}/>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                ))}
                                            </>
                                        }

                                        <div className="question-main-text align-end pe-0 ps-sticky mt-4">
                                            {(!loading && propertyPreferenceQuestions?.length > 0) &&
                                                <button className="button-primary mw-10em ml-68 me-0 mb-5 mb-m-5" onClick={() => saveQuestion()} id="preference-save"  data-test-id="preference-save-button">
                                                    Save
                                                </button>
                                            }
                                        </div>          

                                        {/* If chat not loaded yet */}
                                        {(loading || propertyPreferenceQuestions?.length === 0) ? (
                                            <div className="message-holder mira-chat ml-85 mr-85">
                                                <div className="message-bubble">
                                                    {propertyPreferenceQuestions?.length === 0 && 
                                                        <div className={`label ${propertyPreferenceQuestions?.length === 0  ? 'd-flex font-weight-bold' : ''}`}>
                                                            Agent Mira
                                                        </div>
                                                    }
                                                    <div className="message-text d-flex js-start">
                                                        <ChatLoader />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>                                    
                                        )}
                                    
                                        <div ref={bottomRef} />
                                    </div>
                                </div>

                                <div className="fixed-button mt-3">
                                    <ChatInput userInput={userInput} handleInputChange={handleInputChange} handleKeyDown={handleKeyDown} getChat={getChat} getChatSuggestions={getChatSuggestions} loading={loading} pageName={pageName} />
                                </div>
                            </div>
                        </div>

                        <div className="part left-section">
                            <StickyCard />
                        </div>
                    </div>
                </>
            : !userInfo?.is_recurring ?
                <>
                    <div className="centered-content w-90 h-70 background-preferences">
                        <div className="d-flex jusify-content-start flex-column h-100 justify-content-around">
                            <div className="page-intro d-flex flex-column w-70">
                                <div className="heading3">
                                    Let's get to know you better!
                                </div>
                            </div>
                            <div className="message-holder mt-5 d-flex mira w-50 flex-column">
                                <div className="d-flex message-bubble below-agent flex-column">
                                    <div className="label d-flex font-weight-bold">
                                        Agent Mira
                                    </div>
                                    <div className="message-text font-size-xl">
                                        Hi! Enter your name to get started.
                                    </div>
                                </div>
                                <div className="address-input mt-4">
                                    <input id="user-name" data-test-id="save-nick-name" type="text" onKeyDown={handleInputKey} onChange={handleNameChange} autoFocus placeholder='Enter your name...' ref={userNickname} className='font-size-xl' />
                                    <div className="submit-button next arrow me-2" id="save-user-name" data-test-id="save-btn-nick-name" onClick={saveUsername}>
                                        <FontAwesomeIcon icon={faCircleChevronRight} className='font-size-mid color-blue' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            : <></>}
        </>
    )
}

export default PreferenceHomeContent