import React, { useState, useContext, useEffect, useCallback } from "react";
import { LoaderContext } from "../services/LoaderContext";
import Modal from "../utils/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgotPassword, recordotp, resendOtp } from "../services/apiService";


const cs_width = "35%";

const ForgotPassword = ({ showForgotModal, onClose }) => {
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const [showModal, setShowModal] = useState(showForgotModal);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [loginEmail, setLoginEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [errors, setErrors] = useState({});
    const [timeLeft, setTimeLeft] = useState(30);
    const [isActive, setIsActive] = useState(false);

    // Email Validation
    const validateEmail = useCallback((email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }, []);

    // Timer Logic
    const startTimer = () => {
        setTimeLeft(30);
        setIsActive(true);
    };

    useEffect(() => {
        let timer;
        if (isActive && timeLeft > 0) {
            timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
        } else if (timeLeft === 0) {
            setIsActive(false);
        }
        return () => clearTimeout(timer);
    }, [isActive, timeLeft]);

    useEffect(() => {
        setShowModal(showForgotModal);
    }, [showForgotModal]);

    const handleModalClose = () => {
        setShowModal(false);
        onClose();
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        const errors = {};

        if (!validateEmail(loginEmail)) {
            errors.loginEmail = "Please enter a valid email address.";
        }

        setErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }

        // Mock API Call
        try {
            showLoader();
            const response = await forgotPassword({ "email": btoa(loginEmail) });
            if (response.code === 200) {
                hideLoader();
                toast.success("Verification code sent to your email! "+loginEmail);
                setShowOtpModal(true);
                startTimer();
            }
            else {
                hideLoader();
                toast.error("Email address not found. Please check and try again or sign up for a new account.");
            }
        }
        catch (error) {
            hideLoader();
            toast.error("Error sending verification code. Please try again.");
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        const errors = {};

        if (!otp.trim()) {
            errors.otp = "Please enter the verification code.";
        } 
        else if (otp.length !== 6) {
            errors.otp = "Verification code must be 6 digits.";
        }

        setErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }

        // Mock OTP Validation
        try {
            showLoader();
            const response = await recordotp({ "email": btoa(loginEmail)}, btoa(otp));
            if (response.code === 200) {
                hideLoader();
                toast.success("The password has been sent to your registered email address! "+loginEmail);
                handleModalClose();
            }
            else {
                hideLoader();
                toast.error("Invalid verification code. Please try again.");
            }
        }
        catch (error) {
            hideLoader();
            toast.error("Invalid verification code. Please try again.");
        }
    };

    const otpSend = async (e) => { 
        try {
            showLoader();
            const response = await resendOtp({ "email": btoa(loginEmail) });
            if (response.code === 200) {
                startTimer();
                toast.success("Verification code sent to your email! "+loginEmail);
            }
            else {
                hideLoader();
                toast.error("Error sending verification code. Please try again.");
            }
        }
        catch (error) {
            hideLoader();
            toast.error("Error sending verification code. Please try again.");
        }
    }

    return (
        <Modal show={showModal} onClose={handleModalClose} cswidth={cs_width}>
            <div className="mb-2">
                {!showOtpModal && (
                    <>
                        <h4>Forgot Password</h4>
                        <div className="mt-3">
                            <form onSubmit={handleEmailSubmit} autoComplete="off">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label form-text">
                                        Enter email address <span>*</span>
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control custom-br ${errors.loginEmail ? "is-invalid" : ""}`}
                                        id="email"
                                        placeholder="Enter your email"
                                        value={loginEmail}
                                        onChange={(e) => {
                                            setLoginEmail(e.target.value);
                                            setErrors({ ...errors, loginEmail: "" });
                                        }}
                                    />
                                    {errors.loginEmail && <div className="invalid-feedback">{errors.loginEmail}</div>}
                                </div>

                                <div className="d-flex justify-content-center">
                                    <button type="submit" data-test-id="forgot-password-submit" id="forgot-pass" className="button-primary mw-10em mt-2">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}

                {showOtpModal && (
                    <>
                        <h4>Verification code</h4>
                        <p>
                            Verification code sent to your email id: <span className="fw-bold">{loginEmail}</span>
                        </p>
                        <div className="mt-3">
                            <form onSubmit={handleOtpSubmit} autoComplete="off">
                                <div className="mb-3">
                                    <label htmlFor="otp" className="form-label form-text">
                                        Enter verification code <span>*</span>
                                    </label>
                                    <input
                                        type="number"
                                        className={`form-control custom-br ${errors.otp ? "is-invalid" : ""}`}
                                        id="otp"
                                        placeholder="Enter verification code"
                                        value={otp}
                                        onChange={(e) => {
                                            setOtp(e.target.value);
                                            setErrors({ ...errors, otp: "" });
                                        }}
                                    />
                                    {errors.otp && <div className="invalid-feedback">{errors.otp}</div>}
                                </div>

                                <div className="d-flex justify-content-center">
                                    <button type="submit" data-test-id="code-pass-submit" id="code-pass" className="button-primary mw-10em mt-2">
                                        Submit
                                    </button>
                                </div>
                                <div className="d-flex js-center mt-2">
                                    {isActive ? (
                                        <span className="resend-text">
                                            Resend verification code in <strong>{timeLeft} sec.</strong>
                                        </span>
                                    ) : (
                                        <span className="forgot-text" onClick={otpSend}>
                                            Resend verification code
                                        </span>
                                    )}
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default ForgotPassword;
