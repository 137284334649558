import React from "react";
import Slider from "react-slick";

const ImageCarousel = ({ selectedImage }) => {

    const settings = {
        dots: true,
        infinite: selectedImage.length > 1, // Infinite scroll only if more than one slide
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: selectedImage.length > 1, // Show arrows only if more than one slide
        initialSlide: selectedImage.findIndex((card) => card.isActive === true) || 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(2, selectedImage.length), // Adjust slidesToShow based on the number of images
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="slider-main">
            {selectedImage?.length === 1 ? (
                <div className="full-screen-content">
                    <img src={`data:image/jpeg;base64,${selectedImage[0]?.image}`} alt="Slide 1" className="full-screen-image"  />
                </div>
            ) : (
                <Slider {...settings}>
                    {selectedImage.map((card, idx) => (
                        <div key={idx} className="full-screen-content">
                            <img src={`data:image/jpeg;base64,${card?.image}`}  alt={`Slide ${idx + 1}`} className="full-screen-image"  />
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
};

export default ImageCarousel;
