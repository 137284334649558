import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ImageCarousel from "./standout-images";

const ImageFullView = ({ selectedImage, closeModal }) => {

    return (
        <div className="standout-modal-overlay" onClick={closeModal}>
            <div className="standout-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="standout-close-button z-index-top" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                {Array.isArray(selectedImage) && selectedImage?.length > 0  ? (
                    <>
                        <ImageCarousel selectedImage={selectedImage} />
                    </>
                ) : (
                    <>
                        <div className="slider-main">
                            <div className="full-screen-content">
                                <img src={selectedImage} alt="Full view" className="full-screen-image" />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ImageFullView;
