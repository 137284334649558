import React, { useState, useContext, useEffect } from "react";
import { LoaderContext } from "../services/LoaderContext";
import Modal from "../utils/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyUser, resendOtp } from "../services/apiService";

const cs_width = "35%";

const VerificationModal = ({ showVerifyModal, loginEmail, onClose, onSuccess }) => {
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const [showModal, setShowModal] = useState(showVerifyModal);
    const [otp, setOtp] = useState("");
    const [errors, setErrors] = useState({});
    const [timeLeft, setTimeLeft] = useState(30);
    const [isActive, setIsActive] = useState(false);

    // Timer Logic
    const startTimer = () => {
        setTimeLeft(30);
        setIsActive(true);
    };

    useEffect(() => {
        let timer;
        if (isActive && timeLeft > 0) {
            timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
        } 
        else if (timeLeft === 0) {
            setIsActive(false);
        }
        return () => clearTimeout(timer);
    }, [isActive, timeLeft]);

    useEffect(() => {
        setShowModal(showVerifyModal);
    }, [showVerifyModal]);

    const handleModalClose = () => {
        setShowModal(false);
        onClose();
    };

    const returnUser = () => {
        onSuccess();
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        const errors = {};

        if (!otp.trim()) {
            errors.otp = "Please enter the verification code.";
        } 
        else if (otp.length !== 6) {
            errors.otp = "Verification code must be 6 digits.";
        }

        setErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }
        // Mock OTP Validation
        try {
            showLoader();
            const response = await verifyUser({ "email": btoa(loginEmail)}, btoa(otp));
            if (response.code === 200) {
                hideLoader();
                returnUser();
                handleModalClose();
            }
            else {
                hideLoader();
                toast.error("Invalid Code. Please try again.");
            }
        }
        catch (error) {
            hideLoader();
            toast.error("Invalid Code. Please try again.");
        }
    };

    const otpSend = async (e) => { 
        try {
            showLoader();
            const response = await resendOtp({ "email": btoa(loginEmail) });
            if (response.code === 200) {
                startTimer();
                toast.success("Verification code sent to your email "+loginEmail);
            }
            else {
                hideLoader();
                toast.error("Error sending code. Please try again.");
            }
        }
        catch (error) {
            hideLoader();
            toast.error("Error sending code. Please try again.");
        }
    }

    return (
        <Modal show={showModal} onClose={handleModalClose} cswidth={cs_width}>
            <div className="mb-2">
                <h4>Email Verification</h4>
                <p>
                    Verification code has been sent to your registered email address.
                </p>
                <div className="mt-3">
                    <form onSubmit={handleOtpSubmit} autoComplete="off">
                        <div className="mb-3">
                            <label htmlFor="otp" className="form-label form-text">
                                Enter verification code <span>*</span>
                            </label>
                            <input
                                type="number"
                                className={`form-control custom-br ${errors.otp ? "is-invalid" : ""}`}
                                id="otp"
                                placeholder="Enter verification code"
                                value={otp}
                                onChange={(e) => {
                                    setOtp(e.target.value);
                                    setErrors({ ...errors, otp: "" });
                                }}
                            />
                            {errors.otp && <div className="invalid-feedback">{errors.otp}</div>}
                        </div>

                        <div className="d-flex justify-content-center">
                            <button type="submit" className="button-primary mw-10em mt-2">
                                Submit
                            </button>
                        </div>
                        <div className="d-flex js-center mt-2">
                            {isActive ? (
                                <span className="resend-text">
                                    Resend verification code in <strong>{timeLeft} sec.</strong>
                                </span>
                            ) : (
                                <span className="forgot-text" onClick={otpSend}>
                                    Resend verification code
                                </span>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default VerificationModal;
